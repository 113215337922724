// @media screen and (-webkit-min-device-pixel-ratio: 0) {
//   ::i-block-chrome,
//   .width-controller {
//     width: 28% !important;
//     // border: 10px solid blue;
//   }
// }

/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .width-controller {
      width: 30% !important;
      // border: 10px solid blue;
    }
  }
}
.stop-scrolling {
  height: 100vh;
  overflow-y: hidden !important;
}
.desktop-view-marge {
  display: block;

  .add-banner-holder {
    background: var(--white);
    box-shadow: 0px 0px 0px 1px rgba(36, 18, 77, 0.08),
      0px 16px 24px -8px rgba(36, 18, 77, 0.32);
    border-radius: 8px;

    .abh-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;

      .h-cart-icon {
      }
    }

    .abh-mid-holder {
      .abh-mh-sec1 {
        padding: 0 24px;
        display: flex;
        padding-bottom: 50px;
        border-bottom: 1px solid var(--graydd);

        .abh-mh-s1-left {
          width: 450px;

          .abh-ms1l-banner-holder {
            width: 450px;
            height: 500px;
            background: var(--grayee);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;

            .abh-ms1l-bh-upload-image {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              .addbg-icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-bottom: 12px;
              }
            }

            .abh-ms1l-bh-uploaded-img-box {
              width: 100%;
              height: 500px;

              .abh-ms1l-bh-uploaded-img {
                width: 100%;
                height: 500px;
              }
            }
          }
        }

        .abh-mh-s1-right {
          // min-width: 50%;
          // width: auto;
          display: flex;
          align-items: flex-end;

          .abh-ms1l-content-holder {
            // padding-left: 50px;
            width: 100%;
          }

          @media (min-width: 1400px) {
            min-width: 56%;
          }

          @media (min-width: 1800px) {
            min-width: 65%;
          }
        }
      }

      .abh-mh-sec2 {
        padding: 0 16px;
        padding-top: 24px;

        .abh-mh-s2-add-product-uploaded-box {
          margin-bottom: 24px;
          position: relative;

          &:nth-child(4) {
            padding-right: 0px;
          }

          &:nth-child(8) {
            padding-right: 0px;
          }

          &:nth-child(12) {
            padding-right: 0px;
          }

          .abh-mh-s2-apub-img {
            width: 218px;
            height: 218px;
            margin-bottom: 8px;
          }

          .abh-mh-s2-pr-dtl-truncate {
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .abh-mh-s2-add-product-upload {
          width: 214px;
          height: 214px;
          border: 2px dashed rgba(28, 27, 31, 0.32);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          // input[type="file"]{
          //     position: absolute;
          //     top: -9999999;
          //     filter: alpha(opacity=0);
          //     opacity: 0;
          //     width:0;
          //     height:0;
          //     outline: none;
          //     cursor: inherit;
          // }
        }
      }

      .col-2width {
        flex-basis: 19.98%;
        max-width: 19.98%;
      }

      .abh-mh-sec3 {
        padding: 0 24px;
        padding-top: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--graydd);
      }

      .curated-brands-logo-box {
        background: var(--white);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
          0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        width: 170px;
        height: 170px;
        padding: 10px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        .curated-brands-lb-logo {
          width: auto;
          height: auto;
        }
      }
    }
  }

  .textarea-textable {
    border: none;
    outline: none;
    height: 100px !important;
    width: 100% !important;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
    resize: unset;
    padding: 0;
  }

  @media (max-width: 1199px) {
    display: none;
  }
}

.mobile-view-marge {
  display: none;

  @media (max-width: 1199px) {
    display: block;

    .add-banner-holder {
      background: var(--white);
      box-shadow: 0px 0px 0px 1px rgba(36, 18, 77, 0.08),
        0px 16px 24px -8px rgba(36, 18, 77, 0.32);
      border-radius: 8px;

      .abh-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px;

        .h-cart-icon {
        }
      }

      .abh-mid-holder {
        .abh-mh-sec1 {
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          padding-bottom: 50px;
          border-bottom: 1px solid var(--graydd);

          .abh-mh-s1-left {
            width: 100%;

            .abh-ms1l-banner-holder {
              height: 395px;
              background: var(--grayee);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              overflow: hidden;

              .abh-ms1l-bh-upload-image {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .addbg-icon {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  margin-bottom: 12px;
                }
              }

              .abh-ms1l-bh-uploaded-img-box {
                width: 100%;
                height: 395px;

                @media (min-width: 768px) {
                  height: 495px;
                }

                .abh-ms1l-bh-uploaded-img {
                  width: 100%;
                  height: 395px;

                  @media (min-width: 768px) {
                    height: 495px;
                  }
                }
              }

              @media (min-width: 768px) {
                height: 495px;
              }
            }
          }

          .abh-mh-s1-right {
            width: 100%;
            display: flex;
            align-items: flex-end;

            .abh-ms1l-content-holder {
              padding-left: 0px;
              padding-top: 24px;
              width: 100%;
            }
          }
        }

        .abh-mh-sec2 {
          padding: 0 16px;
          padding-top: 24px;

          .abh-mh-s2-add-product-uploaded-box {
            margin-bottom: 24px;
            position: relative;

            &:nth-child(4) {
              padding-right: 0px;
            }

            &:nth-child(8) {
              padding-right: 0px;
            }

            &:nth-child(12) {
              padding-right: 0px;
            }

            .abh-mh-s2-apub-img {
              width: 100%;
              height: 162px;
              margin-bottom: 8px;
              object-fit: contain;
            }

            .abh-mh-s2-pr-dtl-truncate {
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .abh-mh-s2-add-product-upload {
            width: 100%;
            height: 162px;
            border: 2px dashed rgba(28, 27, 31, 0.32);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // input[type="file"]{
            //     position: absolute;
            //     top: -9999999;
            //     filter: alpha(opacity=0);
            //     opacity: 0;
            //     width:0;
            //     height:0;
            //     outline: none;
            //     cursor: inherit;
            // }
          }
        }

        .col-2width {
          flex-basis: 13.98%;
          max-width: 13.98%;
        }

        .abh-mh-sec3 {
          padding: 0 16px;
          padding-top: 24px;
          padding-bottom: 16px;
          border-bottom: 1px solid var(--graydd);
        }

        .curated-brands-logo-box {
          background: var(--white);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
            0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          width: 121px;
          height: 121px;
          padding: 10px;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          margin-top: 2px;

          .curated-brands-lb-logo {
            width: auto;
            height: auto;
          }

          @media (min-width: 768px) {
            width: 160px;
            height: 160px;
          }

          @media (min-width: 991px) {
            width: 210px;
            height: 210px;
          }
        }
      }
    }

    .textarea-textable {
      border: none;
      outline: none;
      // height: 100px !important;
      width: 100% !important;
      font-size: 18px;
      font-weight: 600;
      font-family: "Inter";
      resize: unset;
      padding: 0;
      color: var(--black1c);
    }
  }
}

// drawer css
.close-drawe-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
}

.drawer-bottom {
  padding: 30px 24px 24px 24px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 0px;
    right: 0px;
    margin: auto;
    height: 4px;
    width: 45px;
    background: #bdbdbd;
    border-radius: 40px;
  }
}

.sidebar-toggle {
  padding: 40px 24px 24px 24px;

  .sidebar-list {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    padding: 16px 24px;
    background-color: transparent;
    margin-bottom: 8px;

    &.active {
      background-color: var(--purpleef);
    }
  }
}
