.desktop-view-marge {
  display: block;

  .table-type-view-holder {
    .ttvh-header {
      display: flex;
      align-items: center;
      padding: 10px 0;
    }

    .ttvh-body {
      .ttvh-body-list {
        display: flex;
        align-items: center;
        background-color: var(--white);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
          0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        margin-bottom: 8px;
        height: 96px;

        .ttvh-bl-img-box {
          height: 96px;

          .ttvh-bl-img {
            width: 96px;
            height: 96px;
            border-radius: 4px 0px 0px 4px;
            object-fit: contain;
            object-position: center;
          }
        }

        .alert {
          width: 6px;
          height: 6px;
          background: transparent;
          border-radius: 50%;
          margin-right: 4px;
          display: flex;

          &.green {
            background: var(--success);
          }

          &.red {
            background: var(--error);
          }
        }

        .threedotgray {
          cursor: pointer;
        }
      }
    }

    .width1 {
      width: 45%;
    }

    .width2 {
      width: 18%;
    }

    .width3 {
      width: 15%;
    }

    .width4 {
      width: 12%;
    }

    .width5 {
      width: 5%;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
}

.mobile-view-marge {
  display: none;

  @media (max-width: 1199px) {
    display: block;

    .table-type-view-holder {
      .ttvh-header {
        display: flex;
        align-items: center;
        padding: 10px 0;
      }

      .ttvh-body {
        .ttvh-body-list {
          display: flex;
        

          background-color: var(--white);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
            0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          margin-bottom: 12px;
          width: 100%;
          height: 96px;
        

          .ttvh-bl-img-box {
            height: 96px;

            .ttvh-bl-img {
              width: 66px;
              height: 66px;
              border-radius: 4px 0px 0px 4px;
              object-fit: contain;
              object-position: center;
            }
          }

          .alert {
            width: 6px;
            height: 6px;
            background: transparent;
            border-radius: 50%;
            margin-right: 4px;
            display: flex;

            &.green {
              background: var(--success);
            }

            &.red {
              background: var(--error);
            }
          }

          .threedotgray {
            cursor: pointer;
          }
        }
      }

      .texttruncate {
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: 768px) {
          width: 350px;
        }

        @media (min-width: 992px) {
          width: 550px;
        }

        @media (max-width: 374px) {
          width: 150px;
        }
      }

      .width1 {
        width: 405px;
      }

      .width2 {
        width: 180px;
      }

      .width3 {
        width: 150px;
      }

      .width4 {
        width: 120px;
      }

      .width5 {
        width: 50px;
      }
    }
  }
}
// .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
//   box-shadow: 0px 0px 1px rgb(0 0 0 / 15%), 0px 1px 3px 1px rgb(0 0 0 / 5%) !important;
//   background-color: #f9f6f2 !important;

//   border-radius: 15% !important;
// }

// .css-gju83y-MuiButtonBase-root-MuiMenuItem-root:nth-of-type(1) {
//   border-bottom: 1px solid #e6e1e5 !important;
//   color: #b3261e !important;
//   justify-content: center;
// }
// .css-gju83y-MuiButtonBase-root-MuiMenuItem-root:nth-of-type(2) {
//   border-bottom: 1px solid #e6e1e5 !important;
//   justify-content: center;
// }
// .css-gju83y-MuiButtonBase-root-MuiMenuItem-root:nth-of-type(3) {
//   justify-content: center;
// }
