.popup-holder {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  .ph-backdrop-box {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: 6;

    .ph-container-box {
      height: 100%;
      outline: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .ph-paper-box {
        &.width470 {
          width: 470px;
          max-width: 470px;
        }

        &.width430 {
          width: 430px;
          max-width: 430px;
        }

        &.width420 {
          width: 420px;
          max-width: 420px;
        }

        &.width380 {
          width: 380px;
          max-width: 380px;
        }

        &.width320 {
          width: 320px;
          max-width: 320px;
        }

        &.width984 {
          width: 984px;
          max-width: 984px;
        }

        &.width860 {
          width: 860px;
          max-width: 860px;
          // min-height: calc(100% - 64px);
          height: 100%;
        }

        background-color: var(--grayf9);
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 16px;
        box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
          0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
        margin: 0px;
        position: relative;
        overflow-y: auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        max-height: calc(100% - 64px);
        max-width: 600px;
        padding: 24px 24px 24px 24px;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: #f9f6f2 transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          background-color: transparent;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #f9f6f2;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-track {
          border: 0.0625rem solid transparent;
          border-radius: 2px;
        }

        .modal-close {
          width: 24px;
          height: 24px;
          object-fit: contain;
          cursor: pointer;
        }
        .fullWidth {
          width: 100%;
          padding-left: 10px;
        }

        .textfield {
          margin: 0px 0px 24px 0px !important;
        }

        .form-group {
          .ipnputlabel {
            bottom: auto;
            top: 57px;
          }
        }

        .success-icon {
          margin-bottom: 20px;
          margin-top: 10px;
          width: 220px;
          height: 97px;
          object-fit: contain;
        }

        .react-tel-input .country-list {
          position: fixed !important;
        }

        .otp-ipt-grp1 {
          border: 1px solid var(--graybb);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: auto;
          margin: 0 10px;
        }

        .otp-ipt-grp2 {
          border: 1px solid var(--graybb);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: auto;
          margin: 0 10px;
        }

        .otp-input {
          width: 8px;
          height: 16px;
          border: none;
          border-right: 1px solid var(--graybb);
          border-radius: 8px 0px 0px 8px;
          text-align: center;
          font-family: "Muli";
          font-size: 14px;
          outline: none;
          padding: 20px 28px;

          @media (max-width: 576px) {
            padding: 20px 18px;
          }

          &:last-child {
            border-right: none;
            border-radius: 0px 8px 8px 0px;
          }
        }
      }
    }
  }
}

// add product popup css
.popdropbutton {
  border: none;
  background: transparent;
  padding: 0 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  font-family: "Inter";
  padding: 8px 16px;
}
.modal_bottom_buttons {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: var(--white);
  border-radius: 20px 20px 0px 0px;
  border-top: 50px solid var(--graye8);
  height: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  // border: 2px solid red;
  margin-bottom: 5px;
  // box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.15);
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
}

.productdropbox {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  width: 320px;

  .pdb-hdr {
    background-color: var(--white);
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid var(--graye8);
    height: 48px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .pdb-body {
    background: var(--grayf9);
    border-radius: 0px 0px 20px 20px;
    height: 320px;
    padding: 12px 16px 0 16px;
    overflow-x: hidden;
    overflow-y: auto;

    .listing-data-box {
      padding: 8px 16px;
      margin-bottom: 8px;

      &.ldb-active {
        background: rgba(134, 82, 255, 0.16);
        border-radius: 666px;

        .ldb-text {
          color: var(--black1c);
        }
      }
    }
  }
}

.search-box {
  padding: 4px 8px;
  display: flex;
  align-items: center;

  .search-icon {
    margin-right: 8px;
  }

  .search-input {
    width: 65px;
    font-size: 16px;
    font-weight: 600;
    color: var(--black1c);
    font-family: "Inter";
    border: none;
    background-color: transparent;
    outline: none;
    transition: width 0.8s;

    &:focus {
      width: 120px;
    }
  }
}

.added-chip {
  display: flex;
  align-items: center;
  background: rgba(249, 246, 242, 0.4);
  // backdrop-filter: blur(16px) ;

  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);
  border-radius: 666px;
  padding: 8px 16px;
  // position: absolute;
  width: 100px;
  margin-left: 75px;
  top: 3px;
  left: 80px;
  // border: 1px solid blue;
}

.add-product-uploaded-holder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  .add-product-uploaded-box {
    // margin-bottom: 24px;
    // padding-right: 24px;
    position: relative;

    // &:nth-child(4),
    // &:nth-child(8),
    // &:nth-child(12),
    // &:nth-child(16),
    // &:nth-child(20),
    // &:nth-child(24),
    // &:nth-child(28),
    // &:nth-child(32),
    // &:nth-child(36),
    // &:nth-child(40),
    // &:nth-child(44),
    // &:nth-child(48),
    // &:nth-child(52),
    // &:nth-child(56),
    // &:nth-child(60),
    // &:nth-child(64),
    // &:nth-child(68),
    // &:nth-child(72),
    // &:nth-child(76),
    // &:nth-child(80) {
    //   padding-right: 0px;
    // }

    .apub-img {
      width: 183px;
      height: 180px;
      margin-bottom: 8px;
    }
  }
}

// product detail popup css
.mc-24 {
  width: 30px !important;
  height: 30px !important;
}

.product-detail-holder {
  display: flex;
  align-items: flex-start;
  min-height: 460px;

  &.product-detail-holder--newheight {
    min-height: 592px;
    position: relative;
    height: 100%;

    .pdh-left {
      // max-height: 592px;
      min-height: 100%;
    }

    .pdh-right {
      max-height: 548px;
      min-height: 548px;
    }

    .pd-btn-holder {
      position: absolute !important;
      bottom: 24px !important;
      right: 24px !important;
      margin-right: 0 !important;
    }
  }

  .sweet-loading {
    margin: auto;
  }

  .pdh-left {
    width: 50%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 460px;

    .pdg-l-img {
      width: 430px;
      height: auto;
    }

    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f9f6f2 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
      background-color: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f9f6f2;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      border: 0.0625rem solid transparent;
      border-radius: 2px;
    }
  }

  .pdh-right {
    width: 50%;
    margin: 45px auto 0px auto;
    padding: 0 50px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 415px;

    .pd-btn-holder {
      position: sticky;
      bottom: 20px;
      margin-right: -26px;

      .pd-button {
        position: sticky;
        bottom: 0px;
        right: 0px;
      }

      .pd-button2 {
        position: sticky;
        bottom: 0px;
        right: 0px;
        font-size: 16px;
        font-weight: 600;
        color: var(--white);
        background-color: var(--black1c);
        border-radius: 616px;
        height: 40px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        border: none;
      }
    }

    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f9f6f2 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
      background-color: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f9f6f2;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      border: 0.0625rem solid transparent;
      border-radius: 2px;
    }
  }
}

// upload banner popup css
.upload-banner-popup-box {
  border: 2px dashed var(--graye5);
  height: 250px;
  margin: 0px 0px 87px 0px;
  // display: flex;
  align-items: center;
  justify-content: center;
 
  width: 100%;

  .abh-ms1l-bh-upload-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    .addbg-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 12px;
    }

    .banner-preview {
      width: 50%;
      height: 100%;
    }
    .banner-preview-mobile {
      width: 50%;
      height: 100%;
    }
    .banner-preview-banner {
      background-color: #d3d3d3;
      width: 20.7rem;
      height: 40px;
      font-size: 13px;
      font-weight: bold;
      color: #585858;
     
     
    }

    .banner-preview-banner-mobile {
      background-color: #d3d3d3;
      width: 100%;
     height: 40px;
     

      font-size: 13px;
      font-weight: bold;
      color: #585858;
     
     
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .delete-banner {
      color: black;
      font-size: medium;
      display: flex;
      justify-content: center;
      // align-items: center;
      
    }
    input[type="file"] {
      position: absolute;
      top: -9999999;
      filter: alpha(opacity=0);
      opacity: 0;
      width: 0;
      height: 0;
      outline: none;
      cursor: inherit;
    }
  }
}

.uploaded-banner-popup-box {
  width: 100%;
  margin: 20px 0;

  .ubpb-img-box {
    position: relative;
    width: 100%;
    height: 127px;
    background: var(--graye5);
    object-fit: cover;
    align-items: center;
    align-self: center;
    text-align: center;
    display: flex;
    overflow: hidden;

    .ubpb--img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      align-items: center;
      align-self: center;
      display: flex;
      margin: 0 auto;
    }
  }

  .ubpb-cdetail-box {
    background: #dadada;
  }
}

.insta-upload-img-popupholder {
  border: 2px dashed var(--graye5);
  border-radius: 4px;
  height: 250px;
  margin: 0px 0px 87px 0px;

  .instapopupbutton {
    background: var(--black1c);
    border: none;
    padding: 10px 24px;
    border-radius: 666px;
    display: flex;
    align-items: center;
    margin: auto;
  }
}

.popupdefaultimgbox {
  position: relative;

  .popupdefaultimg {
    width: 100%;
    height: 203px;
  }
}

.textarea-textable {
  &.description {
    background-color: transparent;
    border: 1px solid #d3d3d3 !important;

    border-radius: 4px !important;
    padding: 16px !important;
    width: 100%;
    min-height: 143px;
    max-height: 143px;
    outline: none;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f9f6f2 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
      background-color: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f9f6f2;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      border: 0.0625rem solid transparent;
      border-radius: 2px;
    }
  }
  &.changeBorderColor {
    border: 1px solid #6750a4 !important;
  }
}

.description-label {
  position: absolute;
  top: -7px;
  left: 10px;
  padding: 0px 8px;
  background: #f9f6f2;
  font-size: 12px;
  color: #6750a4;
}

.textarea-textable.description + label.description-label + div {
  display: block;
  text-align: right;
}

.pl-sys-btn {
  height: 44px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.replaceBannerButton {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}