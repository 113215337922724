.desktop-view-marge {
  display: block;

  .add-banner-holder {
    background: var(--white);
    box-shadow: 0px 0px 0px 1px rgba(36, 18, 77, 0.08),
      0px 16px 24px -8px rgba(36, 18, 77, 0.32);
    border-radius: 8px;

    .abh-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;

      .h-cart-icon {
      }
    }

    .abh-mid-holder {
      position: relative;
      .abh-mh-sec1 {
        padding: 0 24px;
        display: flex;
        padding-bottom: 50px;
        border-bottom: 1px solid var(--graydd);

        .abh-mh-s1-left {
          width: 476px;

          .abh-ms1l-banner-holder {
            width: 476px !important;
            height: 500px;
            background: var(--grayee);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;

            .abh-ms1l-bh-upload-image {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              // position: relative;

              .addbg-icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-bottom: 12px;
              }
            }

            .abh-ms1l-bh-uploaded-img-box {
              width: 100%;
              height: 500px;

              .abh-ms1l-bh-uploaded-img {
                width: 100%;
                height: 500px;
              }
            }
          }
        }

        .abh-mh-s1-right {
          // min-width: 30%;
          // display: flex;
          // align-items: flex-end;
          background-color: white;
          height: 65%;
          // width: 50.3rem;
          width: 47%;
          // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          padding-left: 1%;
          position: relative;
          // margin-left: 30%;
          // padding-right: 8.3rem;
          // margin-right: 15rem;
          margin-top: 7rem;

          .abh-ms1l-content-holder {
            // padding-left: 50px;
            width: 100%;
          }

          // @media (min-width: 1400px) {
          //   min-width: 56%;
          // }

          // @media (min-width: 1800px) {
          //   min-width: 65%;
          // }
        }
      }

      .abh-mh-sec2 {
        padding: 0 16px;
        padding-top: 24px;

        .abh-mh-s2-add-product-uploaded-box {
          margin-bottom: 24px;
          cursor: pointer;
          position: relative;

          &:nth-child(4) {
            padding-right: 0px;
          }

          &:nth-child(8) {
            padding-right: 0px;
          }

          &:nth-child(12) {
            padding-right: 0px;
          }

          .abh-mh-s2-apub-img {
            width: 218px;
            height: 218px;
            margin-bottom: 8px;
            object-fit: contain;
          }

          .abh-mh-s2-pr-dtl-truncate {
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .abh-mh-s2-add-product-upload {
          width: 200px;
          height: 200px;
          border: 2px dashed rgba(28, 27, 31, 0.32);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-left: 4%;
          // input[type="file"]{
          //     position: absolute;
          //     top: -9999999;
          //     filter: alpha(opacity=0);
          //     opacity: 0;
          //     width:0;
          //     height:0;
          //     outline: none;
          //     cursor: inherit;
          // }
        }
      }

      .col-2width {
        flex-basis: 19.98%;
        max-width: 19.98%;
        margin-left: 4%;
      }

      .abh-mh-sec3 {
        padding: 0 24px;
        padding-top: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--graydd);
      }

      .curated-brands-logo-box {
        background: var(--white);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
          0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        width: 100% !important;
        height: 141px !important;
        padding: 0px !important;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        .curated-brands-lb-logo {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  .textarea-textable {
    border: none;
    outline: none;
    height: 100px !important;
    width: 100% !important;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
    resize: unset;
    padding: 0;
  }

  img.preview-banner-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  @media (max-width: 1199px) {
    display: none;
  }
}

.mobile-view-marge {
  display: none;

  @media (max-width: 1199px) {
    display: block;

    .add-banner-holder {
      background: var(--white);
      box-shadow: 0px 0px 0px 1px rgba(36, 18, 77, 0.08),
        0px 16px 24px -8px rgba(36, 18, 77, 0.32);
      border-radius: 8px;

      .abh-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px;

        .h-cart-icon {
        }
      }

      .abh-mid-holder {
        .abh-mh-sec1 {
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          padding-bottom: 50px;
          border-bottom: 1px solid var(--graydd);

          .abh-mh-s1-left {
            width: 100%;

            .abh-ms1l-banner-holder {
              height: 395px;
              background: var(--grayee);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              overflow: hidden;
              position: relative;

              // .abh-ms1l-bh-upload-image {
              //   display: flex;
              //   align-items: center;
              //   justify-content: center;
              //   flex-direction: column;
              //   // position: relative;

              //   .addbg-icon {
              //     width: 40px;
              //     height: 40px;
              //     border-radius: 50%;
              //     margin-bottom: 12px;
              //   }
              // }
              .abh-ms1l-bh-upload-image-mobile {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                // position: relative;

                .addbg-icon {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  margin-bottom: 12px;
                }
              }

              .abh-ms1l-bh-uploaded-img-box {
                width: 100%;
                height: 395px;

                @media (min-width: 768px) {
                  height: 495px;
                }

                .abh-ms1l-bh-uploaded-img {
                  width: 100%;
                  height: 395px;

                  @media (min-width: 768px) {
                    height: 495px;
                  }
                }
              }

              @media (min-width: 768px) {
                height: 495px;
              }
            }
          }

          .abh-mh-s1-right {
            width: 100%;
            display: flex;
            align-items: flex-end;
            border: "1px solid red !important";

            .abh-ms1l-content-holder {
              padding-left: 0px;
              padding-top: 24px;
              width: 100%;
            }
          }
        }

        .abh-mh-sec2 {
          padding: 0 16px;
          padding-top: 24px;

          .abh-mh-s2-add-product-uploaded-box {
            margin-bottom: 24px;
            cursor: pointer;
            position: relative;

            &:nth-child(4) {
              padding-right: 0px;
            }

            &:nth-child(8) {
              padding-right: 0px;
            }

            &:nth-child(12) {
              padding-right: 0px;
            }

            .abh-mh-s2-apub-img {
              width: 100%;
              height: 162px;
              margin-bottom: 8px;
              object-fit: contain;
            }

            .abh-mh-s2-pr-dtl-truncate {
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .abh-mh-s2-add-product-upload {
            width: 100%;
            height: 162px;
            border: 2px dashed rgba(28, 27, 31, 0.32);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // input[type="file"]{
            //     position: absolute;
            //     top: -9999999;
            //     filter: alpha(opacity=0);
            //     opacity: 0;
            //     width:0;
            //     height:0;
            //     outline: none;
            //     cursor: inherit;
            // }
          }
        }

        .col-2width {
          flex-basis: 13.98%;
          max-width: 13.98%;
        }

        .abh-mh-sec3 {
          padding: 0 16px;
          padding-top: 24px;
          padding-bottom: 16px;
          border-bottom: 1px solid var(--graydd);
        }

        .curated-brands-logo-box {
          background: var(--white);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
            0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          width: 121px;
          height: 84px !important;
          padding: 0px !important;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-right: 8px;
          margin-top: 2px;
          .curated-brands-lb-logo {
            // width: auto;
            // height: auto;
            width: 100% !important;
            height: 100% !important;
          }

          @media (min-width: 768px) {
            width: 160px;
            height: 160px;
          }

          @media (min-width: 991px) {
            width: 210px;
            height: 210px;
          }
        }
      }
    }

    .textarea-textable {
      border: none;
      outline: none;
      // height: 100px !important;
      width: 100% !important;
      font-size: 18px;
      font-weight: 600;
      font-family: "Inter";
      resize: unset;
      padding: 0;
      color: var(--black1c);
    }

    img.preview-banner-image {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

// drawer css
.close-drawe-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
}

.drawer-bottom {
  padding: 30px 24px 24px 24px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 0px;
    right: 0px;
    margin: auto;
    height: 4px;
    width: 45px;
    background: #bdbdbd;
    border-radius: 40px;
  }
}

.sidebar-toggle {
  padding: 40px 24px 24px 24px;

  .sidebar-list {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 100px;
    padding: 16px 24px;
    background-color: transparent;
    margin-bottom: 8px;

    &.active {
      background-color: var(--purpleef);
    }
  }
}

.textarea-textable {
  &.discription {
    background-color: transparent;
    // border: 1px solid #6750a4 !important;
    border-radius: 4px !important;
    padding: 16px !important;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
    width: 100%;
    max-height: 300px;
    outline: none;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f9f6f2 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
      background-color: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f9f6f2;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      border: 0.0625rem solid transparent;
      border-radius: 2px;
    }
  }
  &.borderofDescription {
    border: 1px solid #6750a4 !important;
  }
}

.discription-label {
  position: absolute;
  top: -7px;
  left: 10px;
  padding: 0px 8px;
  background: #f9f6f2;
  font-size: 12px;
  color: #6750a4;
}

.textarea-textable.discription + label.description-label + div {
  display: block;
  text-align: right;
}

.text-width-limit-on-sociallinks {
  // width: 200px;
  // width: fit-content;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  // @media (max-width: 575px) {
  //   width: 90%;
  // }
}

.curated-brands-logo-box-cur {
  background: var(--white);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 121px;
  height: 121px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionBreakWord2 {
  word-wrap: break-word !important;
  text-align: justify;
  width: 100%;
}

.descriptionBox::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.hideScrollbarBar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
